import axios from 'axios';

export const httpClient = axios.create({
  // baseURL: 'http://68.178.170.141:5000',
  // baseURL: 'http://localhost:5000',
  // baseURL: 'http://192.168.0.35:5000',
  //  baseURL: 'http://192.168.0.46:5000',
  // baseURL: 'http://192.168.1.26:5000',

  // artnstock1 real
  // baseURL: 'http://192.168.0.17:5000',

  // artnstock2
  baseURL: 'http://97.74.80.230:5000',
});
// export const httpClientWS = axios.create({
//   // baseURL: 'http://68.178.170.141:5000',

//   baseURL: 'ws://192.168.0.15:5000/stomp-endpoint',

//   // baseURL: 'http://192.168.0.15:5000',
// });
