import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, styled, tableCellClasses } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { httpClient } from '../../axios';
import Popup from 'reactjs-popup';

const ProductSub = () => {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'rgb(115 103 240)',
            color: theme.palette.common.white,
            fontSize: 20,

        },

    }));

    const [product, setProduct] = useState([]);
    const [ImageLink, setImageLink] = useState('');
    const [Imagelink2, setImagelink2] = useState('');
    const [Imagelink3, setImagelink3] = useState('');
    const [imageName1, setImageName1] = useState('');
    const [imageName2, setImageName2] = useState('');
    const [imageName4, setImageName4] = useState('');
    const [pro, setPro] = useState([]);
    const [selectProduct, setselectProduct] = useState('');

    const fileInputRef1 = useRef(null);
    const fileInputRef2 = useRef(null);
    const fileInputRef4 = useRef(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [update, setUpadte] = useState(
        {
            productSubCategoryName: "",
            image: "",
            status: "",
            logoImage: "",
            type: "",
            productMainCategoryId: "",
            index: 0
        }

    );

    const updateStructuredObject = (prod) => {

        setUpadte({
            ...update, productSubCategoryName: prod?.productSubCategoryName,
            image: prod?.image,
            productSubCategoryId: prod?.productSubCategoryId,
            status: prod?.status,
            logoImage: prod?.logoImage,
            type: prod?.type,
            productMainCategoryId: prod?.productMainCategoryMaster.productMainCategoryId,
            index: prod?.index
        })


    }

    const filteredData = product.filter(list =>
        list.productSubCategoryName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const handleSearch = event => {
        setSearchQuery(event.target.value);
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



    const getProduct = async () => {
        try {
            const res = await httpClient.get('/product_sub_category_master');
            if (res) {
                setProduct(res.data);
                console.log(res.data);
            }
        }
        catch {
            console.log('error')
        }
    }

    const getprom = async () => {
        try {
            const res = await httpClient.get('/product_main_category_master');
            console.log(res.data);
            setPro(res.data);
        } catch (error) {
            console.error('Error fetching Product Main:', error);

        }
    };
    useEffect(() => {
        getprom();
    }, []);

    const addProduct = async () => {
        try {
            const selectedCountry = pro.find(prod => prod.productMainCategoryId === selectProduct);
            if (selectedCountry) {
                const newproduct = {
                    ...update,
                    productMainCategoryId: selectedCountry.productMainCategoryId 
                };

                await httpClient.post('/product_sub_category_master/create', newproduct)
                getProduct();
            }
        }

        catch (error) {
            console.error('Error adding record:', error);
        }
    };



    const handleInputChange = (e) => {
        setUpadte({
            ...update,
            [e.target.name]: e.target.value,
        });
    };


    useEffect(() => {
        console.log(update);
    }, [update]);

    const handleFileChange2 = async (event, linkNumber) => {
        const img = event.target.files[0];
        if (img) {

            let formData = new FormData();
            formData.append('file', img);
            const res = await httpClient.post(
                '/CloudinaryImageUpload?parameter=false',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            setImageName1(img.name);

            console.log(res.data)
            setUpadte({ ...update, image: res.data })
        };
    };


    const handleFileChange3 = async (event, linkNumber) => {
        const img = event.target.files[0];
        if (img) {

            let formData = new FormData();
            formData.append('file', img);
            const res = await httpClient.post(
                '/CloudinaryImageUpload?parameter=false',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            setImageName2(img.name);

            console.log(res.data)
            setUpadte({ ...update, logoImage: res.data })
        };
    };

    

    const handleButtonClick1 = () => {
        fileInputRef1.current.click();
    };
    const handleButtonClick2 = () => {
        fileInputRef2.current.click();
    };

    const handleButtonClick4 = () => {
        fileInputRef4.current.click();
    };


    const UpdateProduct = async () => {
        const res = await httpClient.put('/product_sub_category_master', update
        );
        getProduct();
        console.log(res.data);

    };

    const handlechange2 = (event) => {
        setselectProduct(event.target.value);
    }


    useEffect(() => {
        getProduct();

    }, []);

    return (
        <Grid justifyContent='center'>
            <Grid item xs={12} md={10} lg={8} >
                <div className='pageMainDiv mt-[20px]'>
                    <p className='lg:text-[25px] text-[16px] pb-[20px] font-semibold mt-[40px]'>
                        Product Sub Category
                    </p>
                    <input type="text" name='name' placeholder="Search by Product Subcategory Name..."
                        value={searchQuery}
                        onChange={(e) => handleSearch(e)}
                        className=' w-[100%] h-[50px] rounded-md border border-[#ABB2B9] pl-4 ' />
                    <TableContainer style={{ height: '400px' }} className='mt-[20px]  '>
                        <Table sx={{ minWidth: 650 }}  size='small' className='border border-[#CECECE] ' stickyHeader>
                            <TableHead>
                                <TableRow >
                                    <StyledTableCell> <div className='w-[150px]'>product SubCategory Name</div></StyledTableCell>
                                    <StyledTableCell><div className='w-[150px]'>Image</div></StyledTableCell>
                                    <StyledTableCell><div className='w-[150px]'>Logo Image</div></StyledTableCell>
                                    <StyledTableCell align='center'><div className='w-[150px]'>Type</div></StyledTableCell>
                                    <StyledTableCell align='center'><div className='w-[150px]'>Product Maincategory Name</div></StyledTableCell>
                                    <StyledTableCell ><div className='w-[150px]'>Image</div></StyledTableCell>
                                    <StyledTableCell align='center'><div className='w-[150px]'>Status</div></StyledTableCell>
                                    <StyledTableCell align='center'><div className='w-[150px]'>Index</div></StyledTableCell>
                                    <StyledTableCell align='center'><div className='w-[150px]'>Action</div></StyledTableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((product, index) => (
                                        <TableRow key={index}>
                                            <TableCell >
                                                <span className='text-[16px] text-black'>
                                                    {product.productSubCategoryName}
                                                </span>
                                            </TableCell>

                                            <TableCell  >
                                                <div>
                                                    <Popup trigger={<div className='text-left'>
                                                        <Button
                                                            onClick={() => setImageLink(product?.image)}
                                                            style={{
                                                                backgroundColor: 'orange',
                                                                color: 'white',
                                                            }}
                                                        >
                                                            Image
                                                        </Button>
                                                    </div>}
                                                        modal
                                                        closeOnDocumentClick
                                                    >
                                                        {(close) => (<div
                                                            className='bg-white'>
                                                            <img src={ImageLink}
                                                                className='w-[100px] lg:w-[300px] lg:h-[300px] h-[100px] rounded-lg  bg-cover shadow' />

                                                        </div>)}
                                                    </Popup>
                                                </div>
                                            </TableCell>

                                            <TableCell  >
                                                <div>
                                                    <Popup trigger={<div className='text-left'>
                                                        <Button
                                                            onClick={() => setImagelink3(product?.logoImage)}
                                                            style={{
                                                                backgroundColor: 'orange',
                                                                color: 'white',
                                                            }}
                                                        >
                                                            Logo
                                                        </Button>
                                                    </div>}
                                                        modal
                                                        closeOnDocumentClick
                                                    >
                                                        {(close) => (<div
                                                            className='bg-white'>
                                                            <img src={Imagelink3}
                                                                className='w-[100px] lg:w-[300px] lg:h-[300px] h-[100px] rounded-lg  bg-cover shadow' />

                                                        </div>)}
                                                    </Popup>
                                                </div>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <span className='text-[16px] text-black'>
                                                    {product.type}
                                                </span>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <span className='text-[16px] text-black'>
                                                    {product.productMainCategoryMaster.productMainCategoryName}
                                                </span>
                                            </TableCell>
                                            <TableCell  align='center'>
                                                <div>
                                                    <Popup trigger={<div className='text-left'>
                                                        <Button
                                                            onClick={() => setImagelink2(product.productMainCategoryMaster.image)}
                                                            style={{
                                                                backgroundColor: 'orange',
                                                                color: 'white',
                                                            }}
                                                        >
                                                            Image
                                                        </Button>
                                                    </div>}
                                                        modal
                                                        closeOnDocumentClick
                                                    >
                                                        {(close) => (<div
                                                            className='bg-white'>
                                                            <img src={Imagelink2}
                                                                className='w-[100px] lg:w-[300px] lg:h-[300px] h-[100px] rounded-lg  bg-cover shadow' />

                                                        </div>)}
                                                    </Popup>
                                                </div>
                                            </TableCell >
                                            <TableCell align='center'>
                                                <span className='text-[16px] text-black'>
                                                    {product.productMainCategoryMaster.status}
                                                </span>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <span className='text-[16px] text-black'>
                                                    {product.index}
                                                </span>
                                            </TableCell>

                                            <TableCell align='center'>
                                                <div className='flex space-x-2 mx-auto'>
                                                    <Popup
                                                        trigger={<div >
                                                            <Button

                                                                style={{
                                                                    backgroundColor: 'orange',
                                                                    color: 'white',
                                                                }}
                                                                onClick={() => {
                                                                    updateStructuredObject(product);
                                                                    // handleCloseForm();
                                                                }}
                                                            >
                                                                Update
                                                            </Button></div>
                                                        }
                                                        modal
                                                        closeOnDocumentClick
                                                    >
                                                        {(close) => (
                                                            <div className='w-[300px] lg:w-[600px]  rounded-lg bg-[white] shadow p-[30px] relative'>
                                                                <div className='flex'>

                                                                    <p className='text-[23px] font-medium ml-4 '>
                                                                        Update Product
                                                                    </p>
                                                                    <button
                                                                        className='ml-auto h-[40px] w-[40px]'
                                                                        onClick={close}
                                                                    >
                                                                        &times;
                                                                    </button>
                                                                </div>

                                                                <div className='lg:flex   lg:space-x-7'>
                                                                    <div className='flex flex-col '>
                                                                        <p className='formhead  mt-[25px] '>Product SubCategory Name</p>
                                                                        <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                            value={update.productSubCategoryName}
                                                                            onChange={handleInputChange}
                                                                            name='productSubCategoryName'

                                                                        />
                                                                    </div>

                                                                    <div className='flex flex-col'>
                                                                        <p className='formhead mt-[25px] '> Status</p>
                                                                        {/* <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                onChange={handleInputChange}
                                                name='seasonalDiscountStatus' /> */}


                                                                        <select
                                                                            className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                            // value={updatedData.seasonalDiscountStatus}
                                                                            onChange={handleInputChange}
                                                                            name='status'
                                                                        >
                                                                            <option
                                                                                value='Active'

                                                                            >
                                                                                Active
                                                                            </option>
                                                                            <option
                                                                                value='InActive'

                                                                            >
                                                                                InActive
                                                                            </option>
                                                                        </select>
                                                                    </div>

                                                                </div>


                                                                <div className='lg:flex   lg:space-x-7'>
                                                                    <div className='flex flex-col'>
                                                                        <p className='formhead  mt-[25px] '>Image</p>
                                                                        <div className='forminput   mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                                                                            <input type='file' className='text-[15px] p-[4px]'
                                                                                style={{ display: 'none' }}
                                                                                accept='image/*'
                                                                                onChange={(e) => { handleFileChange2(e, index) }}
                                                                                ref={fileInputRef1}
                                                                                name='image' multiple />
                                                                            {imageName1 ? (
                                                                                <span>{imageName1}</span>
                                                                            ) : (
                                                                                <a className='cursor-pointer text-[#808080] text-[20px]' onClick={handleButtonClick1}>Img{index + 1}</a>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    <div className='flex flex-col'>
                                                                        <p className='formhead  mt-[25px] '>Logo Image</p>
                                                                        <div className='forminput   mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                                                                            <input type='file' className='text-[15px] p-[4px]'
                                                                                style={{ display: 'none' }}
                                                                                accept='image/*'
                                                                                onChange={(e) => { handleFileChange3(e, index) }}
                                                                                ref={fileInputRef2}
                                                                                name='logoImage' multiple />
                                                                            {imageName2 ? (
                                                                                <span>{imageName2}</span>
                                                                            ) : (
                                                                                <a className='cursor-pointer text-[#808080] text-[20px]' onClick={handleButtonClick2}>Image{index + 1}</a>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                </div>



                                                                <div className='lg:flex lg:space-x-7'>
                                                                    <div className='flex flex-col'>
                                                                        <p className='formhead lg:mt-[20px] mt-[10px] '>Type</p>
                                                                        <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                            name='type'
                                                                            onChange={handleInputChange}
                                                                            value={update.type}
                                                                        />

                                                                    </div>
                                                                    <div className='flex flex-col'>
                                                                        <p className='formhead lg:mt-[20px] mt-[10px]  '>Product Main Category Name</p>

                                                                        <select className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]' placeholder='select Product Name'
                                                                           value={update.productMainCategoryId}   name='productMainCategoryId'
                                                                            onChange={handleInputChange}>
                                                                            <option value="" disabled selected hidden > Select Product Name</option>    
                                                                            {pro.map((pro, index) => (
                                                                                <option key={pro.productMainCategoryId} name='productMainCategoryId' value={pro.productMainCategoryId} >
                                                                                    {pro.productMainCategoryName}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className='lg:flex   lg:space-x-7'>
                                                                    <div className='flex flex-col '>
                                                                        <p className='formhead  mt-[25px] '>Index</p>
                                                                        <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                            onChange={handleInputChange}
                                                                            value={update.index}
                                                                            name='index'
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className='mx-[auto] h-[41px] w-[120px]  mt-[20px]'>
                                                                    <button className='rounded-lg text-[white] bg-[blue] text-[17px] font-semibold h-[41px] w-[120px] '
                                                                        onClick={() => {
                                                                            UpdateProduct();
                                                                            close();
                                                                            // handleCloseForm();
                                                                        }}
                                                                    >
                                                                        Update</button></div>
                                                            </div>
                                                        )}
                                                    </Popup>


                                                </div>
                                            </TableCell>

                                        </TableRow>
                                    ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className='flex'>
                         <Popup
                            trigger={<div >
                                <button
                                    // onClick={() => { handleCloseForm() }}
                                    className='bg-[#7367F0] mt-[20px]  h-[40px] w-[150px] rounded-lg text-[white] text-[16px]'>
                                    Add Product
                                </button></div>
                            }
                            modal
                            closeOnDocumentClick
                        >
                            {(close) => (
                                <div className='w-[300px] lg:w-[600px]  rounded-lg bg-[white] shadow p-[30px] relative'>
                                    <div className='flex'>

                                        <p className='text-[23px] font-medium ml-4 '>
                                            Add Product
                                        </p>
                                        <button
                                            className='ml-auto h-[40px] w-[40px]'
                                            onClick={close}
                                        >
                                            &times;
                                        </button>
                                    </div>

                                    <div className='lg:flex   lg:space-x-7'>
                                        <div className='flex flex-col '>
                                            <p className='formhead  mt-[25px] '>Product SubCategory Name</p>
                                            <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                onChange={handleInputChange}
                                                name='productSubCategoryName'
                                            />
                                        </div>

                                        <div className='flex flex-col'>
                                            <p className='formhead mt-[25px] '> Status</p>



                                            <select
                                                className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                // value={updatedData.seasonalDiscountStatus}
                                                onChange={handleInputChange}
                                                name='status'
                                            >
                                                <option
                                                    value='Active'

                                                >
                                                    Active
                                                </option>
                                                <option
                                                    value='InActive'

                                                >
                                                    InActive
                                                </option>
                                            </select>
                                        </div>

                                    </div>


                                    <div className='flex lg:space-x-7'>
                                        <div className='flex flex-col'>
                                            <p className='formhead  mt-[25px] '>Image</p>
                                            <div className='forminput   mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                                                <input type='file' className='text-[15px] p-[4px]'
                                                    style={{ display: 'none' }}
                                                    accept='image/*'
                                                    onChange={handleFileChange2}
                                                    ref={fileInputRef1}
                                                    name='image' multiple />
                                                {imageName1 ? (
                                                    <span>{imageName1}</span>
                                                ) : (
                                                    <a className='cursor-pointer text-[#808080] text-[30px]' onClick={handleButtonClick1}>+</a>
                                                )}
                                            </div>
                                        </div>

                                        <div className='flex flex-col'>
                                            <p className='formhead  mt-[25px] '>Logo Image</p>
                                            <div className='forminput   mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none '>
                                                <input type='file' className='text-[15px] p-[4px]'
                                                    style={{ display: 'none' }}
                                                    accept='image/*'
                                                    onChange={handleFileChange3}
                                                    ref={fileInputRef2}
                                                    name='logoImage' multiple />
                                                {imageName2 ? (
                                                    <span>{imageName2}</span>
                                                ) : (
                                                    <a className='cursor-pointer text-[#808080] text-[30px]' onClick={handleButtonClick2}>+</a>
                                                )}
                                            </div>
                                        </div>

                                    </div>



                                    <div className='lg:flex lg:space-x-7'>
                                        <div className='flex flex-col'>
                                            <p className='formhead lg:mt-[20px] mt-[10px] '>Type</p>
                                            <input type='text' className='forminput  mt-3 w-[320px] lg:w-[258px] rounded-lg'
                                                name='type'
                                                onChange={handleInputChange}
                                            // value={framedata.width} 
                                            />

                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='formhead lg:mt-[20px] mt-[10px] '>Product Main Category Name</p>

                                            <select className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]' placeholder='select Product id'
                                                value={selectProduct} onChange={handlechange2}  >
                                                <option value="" disabled selected hidden > Select Product Name</option>
                                                {pro.map((pro, index) => (
                                                    <option key={pro.productMainCategoryId} name='productMainCategoryId' value={pro.productMainCategoryId} >
                                                        {pro.productMainCategoryName}
                                                    </option>
                                                ))}
                                            </select>


                                        </div>
                                    </div>
                                    <div className='lg:flex   lg:space-x-7'>
                                        <div className='flex flex-col '>
                                            <p className='formhead  mt-[25px] '>Index</p>
                                            <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                onChange={handleInputChange}
                                                name='index'
                                            />
                                        </div>
                                    </div>

                                    <div className='mx-[auto] h-[41px] w-[120px]  mt-[20px]'>
                                        <button className='rounded-lg text-[white] bg-[blue] text-[17px] font-semibold h-[41px] w-[120px] '
                                            onClick={() => {
                                                addProduct();
                                                close();
                                                // handleCloseForm();
                                            }}
                                        >
                                            Save</button></div>
                                </div>
                            )}
                        </Popup>

                        <TablePagination className='ml-auto'
                            rowsPerPageOptions={[5, 10, 15]}
                            component="div"
                            count={product.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>

                </div>
            </Grid>
        </Grid>
    )
}

export default ProductSub